import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { api, logout } from "src/constants/global-const";
import { addAuthHeader } from "src/utils/auth";

let instance: AxiosInstance;
let eventInstance: AxiosInstance;

const axiosInstance = async () => {
  instance = axios.create({
    baseURL: api + "/api/v1/division",
  });

  // Add the interceptor to the instance
  instance.interceptors.response.use(undefined, (error) => {
    if (error?.response?.status === 403) {
      // logout
      logout();
    }
  });

  instance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
};
const axiosEventInstance = async () => {
  eventInstance = axios.create({
    baseURL: api + "/event/division",
  });

  eventInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => addAuthHeader(config));
};

axiosEventInstance();

axiosInstance();

interface ApiResponse {
  success: boolean;
  message: string;
  data?: any;
  status: number | string;
  // Add other properties as needed
}

interface CounterDraftPayload {
  tuples: { object: string };
  company_code: string;
}

interface CreateCounterDraftPayload {
  division_id: string;
  counter_category: string;
  writes: any[];
  deletes: any[];
  company_code: string;
}

interface CreateProductDraftPayload {
  division_id: string;
  discount_group: string;
  writes: any[];
  deletes: any[];
  company_code: string;
}

export const getAllDivisionDetails = async (): Promise<AxiosResponse> => {
  return instance.get("/details");
};

export const queryDivisionDetails = async (query: string): Promise<ApiResponse> => {
  return instance.get(query);
};

export const getOrderAndDiscount = async (query: string): Promise<AxiosResponse> => {
  return instance.get(`/details?${query}`);
};

export const getCounterDraftHierarchyDetails = async (payload: CounterDraftPayload): Promise<AxiosResponse> => {
  return instance.post(`/counterDraft/details`, payload);
};

export const createCounterDraftHierarchy = async (payload: CreateCounterDraftPayload): Promise<AxiosResponse> => {
  return instance.post(`/counter_draft_hierarchy/add`, payload);
};

export const getDiscountGroupByDivision = async (company_divisioncode: number): Promise<AxiosResponse> => {
  return instance.get("/details?discount_group=true&fields=company_divisioncode&company_divisioncode=" + company_divisioncode);
};

export const getProductDraftHierarchyDetails = async (payload: CounterDraftPayload): Promise<AxiosResponse> => {
  return instance.post(`/productDraft/details`, payload);
};

export const createProductDraftHierarchy = async (payload: CreateProductDraftPayload): Promise<AxiosResponse> => {
  return instance.post(`/product_draft_hierarchy/add`, payload);
};

export const postAddDivision = async (payload: any) => {
  return eventInstance.post(`/add`, { data: payload, event_type: "DIVISION_ADD" });
};

export const putUpdateDivision = async (payload: any, primary_key: string) => {
  return eventInstance.put(`/edit`, { data: payload, event_type: "DIVISION_EDIT", primary_key });
};

export const checkDivisionAccess = async (divisionCode: number, companyCode: number): Promise<AxiosResponse> => {
  return instance.get(`/dgPostUsers`, {
    params: { division_code: divisionCode, company_code: companyCode },
  });
};
