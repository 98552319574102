/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { DownloadIcon } from "lucide-react";
import React, { ChangeEvent, createContext, useContext, useEffect, useState } from "react";
import { Control, Controller, FieldErrors, RegisterOptions, UseFormSetError, useForm } from "react-hook-form";
import CloseIcon from "../assets/close-cross.svg";
import fileIcon from "../assets/draft.svg";
import uploadIcon from "../assets/upload.svg";
import { cn, getValueFromNestedObject, isFieldRequired } from "../utils/utils";
import Autocomplete from "./Autocomplete";
import Badge from "./Badge";
import ErrorMessage from "./ErrorMessage";
import Input from "./Input";
import MultiAutocomplete from "./MultiAutocomplete";
import TextArea from "./TextArea";
import Checkbox from "./Checkbox";
import { useToast } from "src/hooks/useToast";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./Tooltip";

interface SmartFormProps {
  onFormSubmit: (value: any) => void;
  defaultValues?: Record<string, any>;
  schema?: any;
  children?: React.ReactNode;
  isControlled?: boolean;
  filesDefaultValues?: any | any[];
  formInstance?: any;
  attachmentRequired?: any[];
  isFormChangedCheck?: boolean;
}

interface FormContextProps {
  control: Control;
  errors: FieldErrors;
  watch: any;
  setError: UseFormSetError<{ [x: string]: any }>;
  setUploadFiles: any;
  uploadedFiles: any;
  removeFile: any;
  schema: any;
}

interface GenericInputProps {
  type: string;
  label: string;
  placeholder: string;
  fieldName: string;
  className: string;
  blurHandler?: (value: any, fieldName: string) => void;
  inputClassName?: string;
  disabled?: boolean;
  changeHandler?: any;
  debounceTime?: number;
  onKeyDown?: any;
  onKeyUp?: any;
  rules?: any;
  fileUploadKey?: any;
  is_attachment?: boolean;
  tooltipContent?: string;

  // onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface ISmartForm extends React.FC<SmartFormProps> {
  Input: typeof SmartFormInput;
  Autocomplete: typeof SmartFormAutocomplete;
  File: typeof SmartFormFile;
  TextArea: typeof SmartFormTextArea;
  MultiAutocomplete: typeof SmartFormMultiAutocomplete;
  Checkbox: typeof SmartFormCheckbox;
}

// Create a FormContext
const FormContext = createContext<FormContextProps | null>(null);

// Create a FormProvider component
const FormProvider: React.FC<{
  children: React.ReactNode;
  value: FormContextProps;
}> = ({ children, value }) => {
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

const SmartForm: ISmartForm = ({
  onFormSubmit,
  defaultValues = {},
  schema,
  children,
  isControlled = true,
  filesDefaultValues = [],
  formInstance,
  attachmentRequired,
  isFormChangedCheck = true,
}) => {
  const smartFormInstance = useForm({
    resolver: schema ? yupResolver(schema) : undefined,
    mode: "onTouched",
    defaultValues: structuredClone(defaultValues),
  });

  const [defaultValuesToCheck, setDefaultValuesToCheck] = useState(defaultValues);

  const { toast } = useToast();

  let currentFormInstance = formInstance ? formInstance : smartFormInstance;

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
  } = currentFormInstance;

  useEffect(() => {
    if (isControlled)
      reset(structuredClone(defaultValues), {
        keepDirty: false,
        keepDirtyValues: false,
        keepValues: false,
        keepTouched: false,
        keepDefaultValues: false,
        keepIsSubmitted: false,
        keepSubmitCount: false,
        keepIsValid: false,
        keepErrors: false,
      });
  }, [defaultValues]);

  const [uploadedFiles, setUploadedFiles] = useState<any>({});

  useEffect(() => {
    if (filesDefaultValues && filesDefaultValues?.length > 0) {
      const attachmentsObject: any = {};
      filesDefaultValues.forEach((item: any) => {
        if (!attachmentsObject[item?.counterDraftCompanyConfig?.counterDraft_companyConfig_id]) {
          attachmentsObject[item?.counterDraftCompanyConfig?.counterDraft_companyConfig_id] = [];
        }
        attachmentsObject[item?.counterDraftCompanyConfig?.counterDraft_companyConfig_id].push(item);
      });
      setUploadedFiles(attachmentsObject);
    }
  }, []);

  const onSubmit = (data: any) => {
    if (isFormChangedCheck) {
      // Calculate the total number of attachments in uploadedFiles
      const uploadedAttachmentsCount = Object.values(uploadedFiles).reduce((acc, curr: any) => acc + curr.length, 0);

      if (JSON.stringify(defaultValuesToCheck) === JSON.stringify(data) && filesDefaultValues.length === uploadedAttachmentsCount) {
        toast({
          description: "Please make some changes in the form",
          variant: "destructive",
        });
        return;
      }
    }

    let canSubmit = true;
    // check if the uploaded files are required for the form
    attachmentRequired?.forEach((item: any) => {
      if (!uploadedFiles[item?.counterDraft_companyConfig_id]?.length) {
        setError(item?.bindingKey, {
          type: "attachmentRequired",
          message: "Attachments are required",
        });
        canSubmit = false;
      }
    });
    if (!canSubmit) return;
    if (Object.keys(uploadedFiles).length > 0) {
      onFormSubmit({ data, fileData: uploadedFiles });
    } else onFormSubmit(data);
  };

  const setUploadFiles = (data: any, key: string) => {
    setUploadedFiles((prev: any) => {
      return { ...prev, [key]: data };
    });
  };

  const removeFile = (fieldName: string, index: number) => {
    setUploadedFiles((prev: any) => {
      let updatedState = structuredClone(prev);

      updatedState?.[fieldName]?.splice(index, 1);
      return updatedState;
    });
  };

  const resetHandler = () => {
    reset(
      {},
      {
        keepDefaultValues: false,
      }
    );
  };

  useEffect(() => {
    setDefaultValuesToCheck(defaultValues);
  }, [defaultValues]);

  return (
    <form className="w-full" onReset={resetHandler} onSubmit={handleSubmit(onSubmit)}>
      <FormProvider value={{ control, errors, watch, setError, setUploadFiles, uploadedFiles, removeFile, schema }}>
        {children}
        {/* <DevTool control={control} /> */}
      </FormProvider>
    </form>
  );
};

// GenericForm.Input component
const SmartFormInput: React.FC<GenericInputProps & { rules?: RegisterOptions }> = ({
  type,
  label,
  placeholder,
  fieldName,
  className,
  blurHandler,
  inputClassName,
  disabled,
  changeHandler,
  debounceTime,
  onKeyDown,
  onKeyUp,
  rules,
}) => {
  const contextValue = useContext(FormContext);

  if (!contextValue) {
    throw new Error("SmartFormInput must be used within a FormProvider");
  }

  label = isFieldRequired(fieldName, contextValue.schema) ? `${label}*` : label;

  return (
    <div className={`${className}`}>
      <Controller
        name={fieldName}
        control={contextValue.control}
        defaultValue=""
        rules={rules}
        render={({ field }) => (
          <>
            <Input
              {...field}
              onChange={(e) => {
                field.onChange(e);
                if (changeHandler && typeof changeHandler === "function") changeHandler(e.target.value);
              }}
              ref={field.ref}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              type={type}
              label={label}
              debounceTime={debounceTime ? debounceTime : undefined}
              disabled={disabled ? true : false}
              value={field.value}
              className={`w-[363px] mr-auto ${inputClassName}`}
              onBlur={(e) => {
                field?.onBlur();
                if (blurHandler) blurHandler(e.target.value, fieldName);
              }}
              placeholder={placeholder}
            />
          </>
        )}
      />

      <ErrorMessage
        label={label}
        type={contextValue?.errors[fieldName]?.type as string}
        show={!!contextValue.errors[fieldName]}
        message={(contextValue?.errors[fieldName]?.message as string) || ""}
      />
    </div>
  );
};

const SmartFormFile: React.FC<GenericInputProps> = ({
  label,
  placeholder,
  fieldName,
  className,
  blurHandler,
  inputClassName,
  disabled,
  changeHandler,
  debounceTime,
  onKeyDown,
  onKeyUp,
  rules,
  fileUploadKey,
  is_attachment,
  tooltipContent = "Upload file",
}) => {
  const contextValue = useContext(FormContext);

  if (!contextValue) {
    throw new Error("SmartFormFile must be used within a FormProvider");
  }

  label = isFieldRequired(fieldName, contextValue.schema) ? `${label}*` : label;

  const handleFileDownload = (file: any) => {
    if (!file?.upload_path) return;
    const a = document.createElement("a");
    a.href = file?.upload_path;
    a.download = file?.upload_path?.split("/").pop();
    a.click();
  };

  return (
    <div className={`${className}`}>
      <Controller
        name={fieldName}
        control={contextValue.control}
        rules={rules}
        render={({ field }) => (
          <>
            <div className="relative">
              <Input
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  if (changeHandler && typeof changeHandler === "function") changeHandler(e.target.value);
                }}
                ref={field.ref}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                type="text"
                label={label}
                debounceTime={debounceTime ? debounceTime : undefined}
                disabled={disabled ? true : false}
                value={field.value || ""}
                className={`w-[363px] pr-9 mr-auto ${inputClassName}`}
                onBlur={(e) => {
                  field.onBlur();
                  if (blurHandler) blurHandler(e.target.value, fieldName);
                }}
                placeholder={placeholder}
              />

              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <button className="absolute flex items-center justify-center w-5 h-5 p-0.5 bg-gray-200 border rounded-full cursor-pointer top-6 right-3">
                      <img src={uploadIcon} alt="upload" className="cursor-pointer" />
                      <input
                        type="file"
                        multiple
                        className="absolute top-0 left-0 max-w-full max-h-full opacity-0 cursor-pointer"
                        onChange={(e: any) => {
                          if (e.target.files.length > 3 && is_attachment) {
                            contextValue.setError(field.name, {
                              type: label,
                              message: "You can only upload a maximum of 3 images",
                            });
                            return;
                          }
                          if (e.target.files.length < 1 && is_attachment) {
                            contextValue.setError(field.name, {
                              type: label,
                              message: "At least 1 image is required",
                            });
                            return;
                          }
                          if (e.target.files.length > 0 && e.target.files.length < 4 && is_attachment) {
                            contextValue.setError(fieldName, {
                              type: "attachmentRequired",
                              message: "",
                            });
                          }
                          // TODO: Handle file upload
                          const incomingFiles = Array.from(e.target.files);
                          const existingFiles = contextValue.uploadedFiles?.[fileUploadKey] ?? [];
                          const updatedFileList = [...existingFiles, ...incomingFiles];
                          // also it should not exceed 3 files
                          if (updatedFileList.length > 3) {
                            contextValue.setError(field.name, {
                              type: label,
                              message: "You can only upload a maximum of 3 images",
                            });
                            return;
                          }
                          contextValue.setUploadFiles(updatedFileList, fileUploadKey);
                        }}
                      />
                    </button>
                  </TooltipTrigger>
                  <TooltipContent>{tooltipContent || "Upload file"}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex flex-wrap items-center max-w-full gap-3 mt-1">
              {contextValue.uploadedFiles?.[fileUploadKey]?.map((file: any, index: number) => {
                let fileName = "";
                if (file?.upload_path) {
                  fileName = file?.upload_path?.split("/").pop();
                } else {
                  fileName = file?.name?.length > 30 ? `${file?.name?.substring(0, 30)}...` : file?.name;
                }
                return (
                  <Badge key={fileName} className={`gap-1.5 border bg-gray-50 max-w-full `}>
                    <img src={fileIcon} alt="file icon" />
                    {fileName}
                    <img
                      onClick={(e) => {
                        e.stopPropagation();
                        contextValue.removeFile(fileUploadKey, index);
                      }}
                      src={CloseIcon}
                      className="cursor-pointer"
                    />
                    {file?.upload_path && (
                      <DownloadIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFileDownload(file);
                        }}
                        className="w-4 cursor-pointer"
                      />
                    )}
                  </Badge>
                );
              })}
            </div>
          </>
        )}
      />

      <ErrorMessage
        label={label}
        type={contextValue?.errors[fieldName]?.type as string}
        show={!!contextValue.errors[fieldName]}
        message={(contextValue?.errors[fieldName]?.message as string) || ""}
      />
    </div>
  );
};

interface AutocompleteProps {
  placeholder: string;
  fieldName: string;
  uniqueKey: string;
  displayKey: string;
  className: string;
  suggestionList: any[];
  asyncListFunction?: any;
  label: string;
  additionalfield?: string;
  disabled?: boolean;
  autocompleteClassName?: string;
  value?: any;
  isControlled?: boolean;
  onChange?: (value: any, fieldName: string) => void;
  rules?: any;
  isAsyncQuery?: any;
  fieldType?: string;
  additionalDisplayKeySearch?: boolean;
  tertiaryDisplayKey?: string;
  quaternaryDisplayKey?: string;
}

const SmartFormAutocomplete: React.FC<AutocompleteProps> = ({
  placeholder,
  fieldName,
  uniqueKey,
  displayKey,
  suggestionList,
  className,
  label,
  additionalfield,
  onChange,
  asyncListFunction,
  disabled,
  autocompleteClassName,
  value,
  isControlled,
  rules,
  isAsyncQuery,
  fieldType,
  additionalDisplayKeySearch,
  tertiaryDisplayKey,
  quaternaryDisplayKey,
}) => {
  const contextValue = useContext(FormContext);

  if (!contextValue) {
    throw new Error("SmartFormInput must be used within a FormProvider");
  }

  label = isFieldRequired(fieldName, contextValue.schema) ? `${label}*` : label;

  return (
    <div className={className}>
      <Controller
        name={fieldName}
        control={contextValue.control}
        rules={rules}
        render={({ field }) => (
          <>
            <p className="text-[#252525c2] font-bold text-xs ml-3 mb-1">{label}</p>
            <Autocomplete
              suggestionList={suggestionList} // Replace with your suggestion list data
              displayKey={displayKey} // Replace with the key for the display value in your suggestion data
              inputClassName={`w-full h-[33px] ${autocompleteClassName}`}
              additionalfield={additionalfield}
              type={fieldType}
              disabled={disabled ? true : false}
              asyncListFunction={asyncListFunction ? asyncListFunction : undefined}
              placeholder={placeholder}
              isAsyncQuery={isAsyncQuery ? true : false}
              uniqueKey={uniqueKey} // Replace with the key for a unique identifier in your suggestion data
              defaultValue={isControlled ? value : typeof field.value === "object" ? field?.value?.[displayKey] : field.value}
              onSelectionChange={(selectedItem: any) => {
                field.onChange(selectedItem);
                if (onChange) onChange(selectedItem, fieldName);
              }}
              additionalDisplayKey={additionalfield}
              additionalDisplayKeySearch={additionalDisplayKeySearch}
              tertiaryDisplayKey={tertiaryDisplayKey}
              quaternaryDisplayKey={quaternaryDisplayKey}
            />
          </>
        )}
      />
      <ErrorMessage
        label={label}
        type={contextValue?.errors[fieldName]?.type as string}
        show={!!contextValue.errors[fieldName]?.message}
        message={(contextValue.errors[fieldName]?.message as string) || ""}
      />
    </div>
  );
};

const SmartFormTextArea: React.FC<any> = ({
  label,
  placeholder,
  fieldName,
  className,
  blurHandler,
  disabled,
  changeHandler,
  onKeyDown,
  onKeyUp,
  rules,
}) => {
  const contextValue = useContext(FormContext);

  if (!contextValue) {
    throw new Error("SmartFormTextArea must be used within a FormProvider");
  }

  label = isFieldRequired(fieldName, contextValue.schema) ? `${label}*` : label;

  return (
    <>
      <Controller
        name={fieldName}
        control={contextValue.control}
        rules={rules}
        render={({ field }) => (
          <>
            <TextArea
              {...field}
              onChange={(e) => {
                field.onChange(e);
                if (changeHandler && typeof changeHandler === "function") changeHandler(e.target.value);
              }}
              label={label}
              value={field.value}
              className={`w-full mr-auto ${className}`}
              onBlur={(e) => {
                field.onBlur();
                if (blurHandler) blurHandler(e.target.value, fieldName);
              }}
              placeholder={placeholder}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              disabled={disabled}
            />
          </>
        )}
      />

      <ErrorMessage
        label={label}
        type={contextValue?.errors[fieldName]?.type as string}
        show={!!contextValue.errors[fieldName]?.message}
        message={(contextValue.errors[fieldName]?.message as string) || ""}
      />
    </>
  );
};

interface MultiAutocompleteProps {
  placeholder: string;
  fieldName: string;
  uniqueKey: string;
  fieldType?: string;
  displayKey: string;
  className: string;
  suggestionList: any[];
  asyncListFunction?: any;
  label: string;
  additionalfield?: string;
  disabled?: boolean;
  autocompleteClassName?: string;
  value?: any;
  isControlled?: boolean;
  onChange?: (value: any, fieldName: string) => void;
  additionDisplayKey?: string;
  showRequiredMark?: boolean;
  showCityInHospital?: boolean;
  controlledValue?: any[];
}

const SmartFormMultiAutocomplete: React.FC<MultiAutocompleteProps> = ({
  placeholder,
  fieldName,
  uniqueKey,
  displayKey,
  suggestionList,
  className,
  label,
  onChange,
  disabled,
  autocompleteClassName,
  value,
  isControlled,
  showRequiredMark = true,
  controlledValue,
}) => {
  const contextValue = useContext(FormContext);
  const [storedValues, setStoredValues] = useState<any[]>([]);

  if (!contextValue) {
    throw new Error("SmartFormInput must be used within a FormProvider");
  }

  if (showRequiredMark) label = isFieldRequired(fieldName, contextValue.schema) ? `${label}*` : label;

  return (
    <div className={className}>
      <Controller
        name={fieldName}
        control={contextValue.control}
        render={({ field }) => (
          <>
            <p className="text-[#252525c2] font-bold text-xs ml-3 mb-1">{label}</p>{" "}
            <MultiAutocomplete
              suggestionList={suggestionList} // Replace with your suggestion list data
              displayKey={displayKey} // Replace with the key for the display value in your suggestion data
              inputClassName={`w-full h-[33px] ${autocompleteClassName}`}
              disabled={disabled ? true : false}
              placeholder={placeholder}
              controlledValue={controlledValue}
              uniqueKey={uniqueKey} // Replace with the key for a unique identifier in your suggestion data
              defaultValue={isControlled ? value : typeof field.value === "object" ? getValueFromNestedObject(field?.value, displayKey) : field.value}
              onSelectionChange={(selectedItem: any, type: string) => {
                let multiVal = storedValues?.map((item) => item);
                if (type === "add") {
                  multiVal.push(selectedItem);
                } else if (type === "remove") {
                  const foundItemIdx = multiVal.findIndex((item) => String(item[uniqueKey]) === String(selectedItem[uniqueKey]));
                  if (foundItemIdx !== -1) {
                    multiVal.splice(foundItemIdx, 1);
                  }
                } else if (type === "reset") {
                  multiVal = [];
                }
                if (onChange) {
                  onChange(multiVal, fieldName);
                }
                setStoredValues(multiVal);
                field.onChange(multiVal);
              }}
            />
          </>
        )}
      />
      <ErrorMessage
        label={label}
        type={contextValue?.errors[fieldName]?.type as string}
        show={!!contextValue.errors[fieldName]?.message}
        message={(contextValue.errors[fieldName]?.message as string) || ""}
      />
    </div>
  );
};

interface CheckboxProps {
  label: string;
  fieldName: string;
  className: string;
  disabled?: boolean;
  onChange?: (value: any, fieldName: string) => void;
  value?: any;
  isControlled?: boolean;
  containerClassName?: string;
  labelClassName?: string;
}

const SmartFormCheckbox: React.FC<CheckboxProps> = ({
  label,
  fieldName,
  className,
  disabled,
  onChange,
  value,
  isControlled,
  containerClassName,
  labelClassName,
}) => {
  const contextValue = useContext(FormContext);

  if (!contextValue) {
    throw new Error("SmartFormCheckbox must be used within a FormProvider");
  }

  return (
    <div className={className}>
      <Controller
        name={fieldName}
        control={contextValue.control}
        render={({ field }) => (
          <div className={cn(`flex items-center gap-2 ${containerClassName ?? ""}`)}>
            <Checkbox
              disabled={disabled ? true : false}
              checked={isControlled ? value : field.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e.target.checked);
                if (onChange) onChange(e.target.checked, fieldName);
              }}
            />
            <p className={cn(`text-[#7b7a7c] font-medium text-xs ${labelClassName ?? ""}`)}>{label}</p>
          </div>
        )}
      />
      <ErrorMessage
        label={label}
        type={contextValue?.errors[fieldName]?.type as string}
        show={!!contextValue.errors[fieldName]?.message}
        message={(contextValue.errors[fieldName]?.message as string) || ""}
      />
    </div>
  );
};

// Expose Input and Autocomplete components from GenericForm

SmartForm.Input = SmartFormInput;
SmartForm.Autocomplete = SmartFormAutocomplete;
SmartForm.File = SmartFormFile;
SmartForm.TextArea = SmartFormTextArea;
SmartForm.MultiAutocomplete = SmartFormMultiAutocomplete;
SmartForm.Checkbox = SmartFormCheckbox;

export default SmartForm;
