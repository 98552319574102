import { ButtonHTMLAttributes, FC, ReactNode, forwardRef } from "react";
import useDebounce from "src/hooks/useDebounce";
import { cn } from "src/utils/utils";
import Spinner from "./spinner.component";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children?: ReactNode;
  onClick?: (event: React.MouseEvent) => void;
  isLoading?: boolean;
  loaderSize?: "small" | "medium";
  debounceTime?: number;
}

const Button: FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ type, className, children, isLoading, loaderSize, onClick, debounceTime = 300, ...rest }, ref) => {
    const typeOfButton = type || "button";

    const debouncedOnClick = useDebounce((event: React.MouseEvent) => {
      onClick?.(event);
    }, debounceTime);

    return (
      <button
        ref={ref}
        type={typeOfButton}
        disabled={isLoading}
        onClick={(event) => {
          event.stopPropagation();
          debouncedOnClick(event);
        }}
        className={cn(
          `  py-2 px-3 focus:ring-4 focus:ring-purple-600  font-semibold rounded-lg text-sm focus:outline-none inline-flex justify-center items-center ${className}`
        )}
        {...rest}
      >
        {isLoading && <Spinner size={loaderSize} />} {children}
      </button>
    );
  }
);

export default Button;
