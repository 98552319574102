/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { ChangeEvent, createContext, useContext, useEffect, useRef, useState } from "react";
import { Control, Controller, FieldErrors, useForm } from "react-hook-form";
import TextArea from "src/components/ui/text-area.component";
import { useToast } from "src/hooks/useToast";
import { Autocomplete, Checkbox, Input, MultiAutocomplete, Toggle } from "../components";
import { cn, getValueFromNestedObject, isFieldRequired } from "../utils/utils";
import ErrorMessage from "./error-message.component";

interface SmartFormProps {
  onFormSubmit: (value: any) => void;
  defaultValues?: Record<string, any>;
  schema?: any;
  children?: React.ReactNode;
  isControlled?: boolean;
  formInstance?: any;
  isFormChangedCheck?: boolean;
}

interface FormContextProps {
  control: Control;
  errors: FieldErrors;
  setError: any;
  clearErrors: any;
  watch: any;
  schema: any;
  setValue: any;
}

interface GenericInputProps {
  type: string;
  label: string;
  placeholder: string;
  fieldName: string;
  className: string;
  blurHandler?: (value: any, fieldName: string) => void;
  inputClassName?: string;
  disabled?: boolean;
  changeHandler?: any;
  debounceTime?: number;
  onKeyDown?: any;
  onKeyUp?: any;
  value?: any;
  isControlled?: boolean;
  showRequiredMark?: boolean;
  min?: string;
  // onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface ISmartForm extends React.FC<SmartFormProps> {
  Input: typeof SmartFormInput;
  Autocomplete: typeof SmartFormAutocomplete;
  Toggle: typeof SmartFormToggle;
  TextArea: typeof SmartFormTextArea;
  MultiAutocomplete: typeof SmartFormMultiAutocomplete;
  Checkbox: typeof SmartFormCheckbox;
}

// Create a FormContext
const FormContext = createContext<FormContextProps | null>(null);

// Create a FormProvider component
const FormProvider: React.FC<{
  children: React.ReactNode;
  value: FormContextProps;
}> = ({ children, value }) => {
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

const SmartForm: ISmartForm = ({
  onFormSubmit,
  defaultValues = {},
  schema,
  children,
  isControlled = true,
  formInstance,
  isFormChangedCheck = true,
}) => {
  const smartFormInstance = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: structuredClone(defaultValues),
  });

  let currentFormInstance = formInstance ? formInstance : smartFormInstance;

  // const { isValidating } = useFormState({ control: currentFormInstance.control });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    setValue,
  } = currentFormInstance;

  useEffect(() => {
    if (isControlled) {
      reset(structuredClone(defaultValues), {
        keepDirty: false,
        keepDirtyValues: false,
        keepValues: false,
        keepTouched: false,
        keepDefaultValues: false,
        keepIsSubmitted: false,
        keepSubmitCount: false,
        keepIsValid: false,
        keepErrors: false,
      });
    }
  }, [defaultValues]);

  const [defaultValuesToCheck, setDefaultValuesToCheck] = useState(defaultValues);

  const submitTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  useEffect(() => {
    setDefaultValuesToCheck(defaultValues);
  }, []);

  const { toast } = useToast();

  const onSubmit = (data: any) => {
    try {
      if (isSubmittingForm) return;
      console.log("Form submission timer started");
      setIsSubmittingForm(true);

      // Clear any existing timeout
      if (submitTimeoutRef.current) {
        console.log("Clearing existing timeout");
        clearTimeout(submitTimeoutRef.current);
      }
      // Set a timeout to reset isSubmitting state
      submitTimeoutRef.current = setTimeout(() => {
        setIsSubmittingForm(false);
        console.log("Form submission timeout");
      }, 500);

      if (isFormChangedCheck) {
        if (JSON.stringify(defaultValuesToCheck) === JSON.stringify(data)) {
          toast({
            description: "Please make some changes in the form",
            variant: "destructive",
          });
          return;
        }
      }

      onFormSubmit(data);
    } catch (error) {
      setIsSubmittingForm(false);
      console.log(error);
    }
  };

  const resetHandler = () => {
    reset(
      {},
      {
        keepDefaultValues: false,
      }
    );
  };

  return (
    <form className="w-full" onReset={resetHandler} onSubmit={handleSubmit(onSubmit)}>
      <FormProvider value={{ control, errors, watch, schema, setError, clearErrors, setValue }}>
        {children}
        {/* <DevTool control={control} /> */}
      </FormProvider>
    </form>
  );
};

// GenericForm.Input component
const SmartFormInput: React.FC<GenericInputProps> = ({
  type,
  label,
  placeholder,
  fieldName,
  className,
  blurHandler,
  inputClassName,
  disabled,
  changeHandler,
  debounceTime,
  onKeyDown,
  onKeyUp,
  value,
  isControlled,
  showRequiredMark = true,
  min,
}) => {
  const contextValue = useContext(FormContext);

  if (!contextValue) {
    throw new Error("SmartFormInput must be used within a FormProvider");
  }

  if (showRequiredMark) label = isFieldRequired(fieldName, contextValue.schema) ? `${label}*` : label;
  let errorFieldName: any = fieldName;
  if (fieldName?.includes(".")) {
    errorFieldName = fieldName?.split(".")?.reduce((acc: any, key) => acc?.[key], contextValue.errors);
  }
  return (
    <div className={`${className}`}>
      <Controller
        name={fieldName}
        control={contextValue.control}
        render={({ field }) => (
          <>
            <Input
              {...field}
              onChange={(e) => {
                const event: any = e;
                // remove white space from front only.
                if (type !== "number") {
                  event.target.value = event.target.value.replace(/^\s+/, "");
                }
                field.onChange(event);
                if (changeHandler && typeof changeHandler === "function") changeHandler(event.target.value);
              }}
              ref={field.ref}
              onKeyDown={onKeyDown}
              onKeyUp={(e: React.KeyboardEvent) => {
                const event: any = e;
                // remove white space from front only.
                if (type !== "number") {
                  event.target.value = event.target.value.replace(/^\s+/, "");
                }
                if (onKeyUp) onKeyUp(event);
              }}
              type={type}
              min={min}
              label={label}
              debounceTime={debounceTime ? debounceTime : undefined}
              disabled={disabled ? true : false}
              value={isControlled ? value : field.value || ""}
              className={`w-[363px] mr-auto ${inputClassName}`}
              onBlur={(e) => {
                field.onBlur();
                if (blurHandler) blurHandler(e.target.value, fieldName);
              }}
              placeholder={placeholder}
            />
          </>
        )}
      />
      {fieldName?.includes(".") ? (
        <ErrorMessage
          label={label}
          type={contextValue?.errors[fieldName]?.type as string}
          show={!!errorFieldName}
          message={(errorFieldName?.message as string) || ""}
        />
      ) : (
        <ErrorMessage
          label={label}
          type={contextValue?.errors[fieldName]?.type as string}
          show={!!contextValue.errors[fieldName]}
          message={(contextValue?.errors[fieldName]?.message as string) || ""}
        />
      )}{" "}
    </div>
  );
};

interface AutocompleteProps {
  placeholder: string;
  fieldName: string;
  uniqueKey: string;
  fieldType?: string;
  displayKey: string;
  className: string;
  suggestionList: any[];
  asyncListFunction?: any;
  label: string;
  additionalfield?: string;
  disabled?: boolean;
  autocompleteClassName?: string;
  value?: any;
  isControlled?: boolean;
  onChange?: (value: any, fieldName: string) => void;
  additionDisplayKey?: string;
  showRequiredMark?: boolean;
  showCityInHospital?: boolean;
  isCounterMerge?: boolean;
  tertiaryDisplayKey?: string;
  additionalDisplayKeySearch?: boolean;
}

const SmartFormAutocomplete: React.FC<AutocompleteProps> = ({
  placeholder,
  fieldName,
  fieldType,
  uniqueKey,
  displayKey,
  suggestionList,
  className,
  label,
  additionalfield,
  onChange,
  asyncListFunction,
  disabled,
  autocompleteClassName,
  value,
  isControlled,
  additionDisplayKey,
  showRequiredMark = true,
  showCityInHospital = false,
  isCounterMerge = false,
  tertiaryDisplayKey,
  additionalDisplayKeySearch = false,
}) => {
  const contextValue = useContext(FormContext);

  if (!contextValue) {
    throw new Error("SmartFormInput must be used within a FormProvider");
  }

  if (showRequiredMark) label = isFieldRequired(fieldName, contextValue.schema) ? `${label}*` : label;

  let errorFieldName: any = fieldName;
  if (fieldName?.includes(".")) {
    errorFieldName = fieldName?.split(".")?.reduce((acc: any, key) => acc?.[key], contextValue.errors);
  }

  return (
    <div className={className}>
      <Controller
        name={fieldName}
        control={contextValue.control}
        render={({ field }) => (
          <>
            <p className="text-[#7b7a7c] font-medium text-xs ml-3 mb-1">{label}</p>
            <Autocomplete
              tertiaryDisplayKey={tertiaryDisplayKey}
              suggestionList={suggestionList} // Replace with your suggestion list data
              displayKey={displayKey} // Replace with the key for the display value in your suggestion data
              inputClassName={`w-full h-[33px] ${autocompleteClassName}`}
              additionalfield={additionalfield}
              disabled={disabled ? true : false}
              isCounterMerge={isCounterMerge}
              type={fieldType}
              asyncListFunction={asyncListFunction ? asyncListFunction : undefined}
              placeholder={placeholder}
              additionalDisplayKeySearch={additionalDisplayKeySearch}
              showCityInHospital={showCityInHospital}
              additionalDisplayKey={additionDisplayKey}
              uniqueKey={uniqueKey} // Replace with the key for a unique identifier in your suggestion data
              defaultValue={
                isControlled
                  ? value
                  : typeof field.value === "object"
                    ? additionDisplayKey && getValueFromNestedObject(field?.value, displayKey)
                      ? `${getValueFromNestedObject(field?.value, displayKey)} - ${getValueFromNestedObject(field?.value, additionDisplayKey)}`
                      : getValueFromNestedObject(field?.value, displayKey)
                    : field.value
              }
              onSelectionChange={(selectedItem: any) => {
                field.onChange(selectedItem);
                if (onChange) onChange(selectedItem, fieldName);
              }}
            />
          </>
        )}
      />

      {fieldName?.includes(".") ? (
        <ErrorMessage
          label={label}
          type={contextValue?.errors[fieldName]?.type as string}
          show={!!errorFieldName}
          message={(errorFieldName?.message as string) || ""}
        />
      ) : (
        <ErrorMessage
          label={label}
          type={contextValue?.errors[fieldName]?.type as string}
          show={!!contextValue.errors[fieldName]}
          message={(contextValue?.errors[fieldName]?.message as string) || ""}
        />
      )}
    </div>
  );
};

interface MultiAutocompleteProps {
  placeholder: string;
  fieldName: string;
  uniqueKey: string;
  fieldType?: string;
  displayKey: string;
  className: string;
  suggestionList: any[];
  asyncListFunction?: any;
  label: string;
  additionalfield?: string;
  disabled?: boolean;
  autocompleteClassName?: string;
  value?: any;
  isControlled?: boolean;
  onChange?: (value: any, fieldName: string) => void;
  additionDisplayKey?: string;
  showRequiredMark?: boolean;
  showCityInHospital?: boolean;
  controlledValue?: any[];
}

const SmartFormMultiAutocomplete: React.FC<MultiAutocompleteProps> = ({
  placeholder,
  fieldName,
  uniqueKey,
  displayKey,
  suggestionList,
  className,
  label,
  onChange,
  disabled,
  autocompleteClassName,
  value,
  isControlled,
  showRequiredMark = true,
  controlledValue,
}) => {
  const contextValue = useContext(FormContext);
  const [storedValues, setStoredValues] = useState<any[]>([]);

  if (!contextValue) {
    throw new Error("SmartFormInput must be used within a FormProvider");
  }

  if (showRequiredMark) label = isFieldRequired(fieldName, contextValue.schema) ? `${label}*` : label;

  return (
    <div className={className}>
      <Controller
        name={fieldName}
        control={contextValue.control}
        render={({ field }) => (
          <>
            <p className="text-[#7b7a7c] font-medium text-xs ml-3 mb-1">{label}</p>
            <MultiAutocomplete
              suggestionList={suggestionList} // Replace with your suggestion list data
              displayKey={displayKey} // Replace with the key for the display value in your suggestion data
              inputClassName={`w-full h-[33px] ${autocompleteClassName}`}
              disabled={disabled ? true : false}
              placeholder={placeholder}
              controlledValue={controlledValue}
              uniqueKey={uniqueKey} // Replace with the key for a unique identifier in your suggestion data
              defaultValue={isControlled ? value : typeof field.value === "object" ? getValueFromNestedObject(field?.value, displayKey) : field.value}
              onSelectionChange={(selectedItem: any, type: string) => {
                let multiVal = controlledValue ?? storedValues?.map((item) => item);
                if (type === "add") {
                  multiVal.push(selectedItem);
                } else if (type === "remove") {
                  const foundItemIdx = multiVal.findIndex((item) => String(item[uniqueKey]) === String(selectedItem[uniqueKey]));
                  if (foundItemIdx !== -1) {
                    multiVal.splice(foundItemIdx, 1);
                  }
                } else if (type === "reset") {
                  multiVal = [];
                }
                if (onChange) {
                  onChange(multiVal, fieldName);
                }
                setStoredValues(multiVal);
                field.onChange(multiVal);
              }}
            />
          </>
        )}
      />
      <ErrorMessage
        label={label}
        type={contextValue?.errors[fieldName]?.type as string}
        show={!!contextValue.errors[fieldName]?.message}
        message={(contextValue.errors[fieldName]?.message as string) || ""}
      />
    </div>
  );
};

// form toggle component

interface GenericToggleProps {
  label: string;
  fieldName: string;
  className: string;
  disabled?: boolean;
  onChange?: (value: any, fieldName: string) => void;
  value?: any;
  isControlled?: boolean;
  containerClassName?: string;
  labelClassName?: string;
}

const SmartFormToggle: React.FC<GenericToggleProps> = ({
  label,
  fieldName,
  className,
  disabled,
  onChange,
  value,
  isControlled,
  containerClassName,
  labelClassName,
}) => {
  const contextValue = useContext(FormContext);

  if (!contextValue) {
    throw new Error("SmartFormToggle must be used within a FormProvider");
  }

  return (
    <div className={className}>
      <Controller
        name={fieldName}
        control={contextValue.control}
        render={({ field }) => (
          <div className={cn(`flex items-center gap-2 ${containerClassName ?? ""}`)}>
            <Toggle
              disabled={disabled ? true : false}
              checked={isControlled ? value : field.value}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) onChange(e, fieldName);
              }}
            />
            <p className={cn(`text-[#7b7a7c] font-medium text-xs ${labelClassName ?? ""}`)}>{label}</p>
          </div>
        )}
      />
      <ErrorMessage
        label={label}
        type={contextValue?.errors[fieldName]?.type as string}
        show={!!contextValue.errors[fieldName]?.message}
        message={(contextValue.errors[fieldName]?.message as string) || ""}
      />
    </div>
  );
};

const SmartFormTextArea: React.FC<GenericInputProps> = ({
  label,
  placeholder,
  fieldName,
  className,
  blurHandler,
  inputClassName,
  disabled,
  changeHandler,
  value,
  isControlled,
  showRequiredMark = true,
}) => {
  const contextValue = useContext(FormContext);

  if (!contextValue) {
    throw new Error("SmartFormInput must be used within a FormProvider");
  }

  if (showRequiredMark) label = isFieldRequired(fieldName, contextValue.schema) ? `${label}*` : label;
  let errorFieldName: any = fieldName;
  if (fieldName?.includes(".")) {
    errorFieldName = fieldName?.split(".")?.reduce((acc: any, key) => acc?.[key], contextValue.errors);
  }
  return (
    <div className={`${className}`}>
      <Controller
        name={fieldName}
        control={contextValue.control}
        render={({ field }) => (
          <>
            <TextArea
              {...field}
              onChange={(e) => {
                field.onChange(e);
                if (changeHandler && typeof changeHandler === "function") changeHandler(e.target.value);
              }}
              ref={field.ref}
              label={label}
              disabled={disabled ? true : false}
              value={isControlled ? value : field.value || ""}
              className={`w-[363px] mr-auto ${inputClassName}`}
              onBlur={(e) => {
                field.onBlur();
                if (blurHandler) blurHandler(e.target.value, fieldName);
              }}
              placeholder={placeholder}
            />
          </>
        )}
      />
      {fieldName?.includes(".") ? (
        <ErrorMessage
          label={label}
          type={contextValue?.errors[fieldName]?.type as string}
          show={!!errorFieldName}
          message={(errorFieldName?.message as string) || ""}
        />
      ) : (
        <ErrorMessage
          label={label}
          type={contextValue?.errors[fieldName]?.type as string}
          show={!!contextValue.errors[fieldName]}
          message={(contextValue?.errors[fieldName]?.message as string) || ""}
        />
      )}
    </div>
  );
};

interface CheckboxProps {
  label: string;
  fieldName: string;
  className: string;
  disabled?: boolean;
  onChange?: (value: any, fieldName: string) => void;
  value?: any;
  isControlled?: boolean;
  containerClassName?: string;
  labelClassName?: string;
}

const SmartFormCheckbox: React.FC<CheckboxProps> = ({
  label,
  fieldName,
  className,
  disabled,
  onChange,
  value,
  isControlled,
  containerClassName,
  labelClassName,
}) => {
  const contextValue = useContext(FormContext);

  if (!contextValue) {
    throw new Error("SmartFormCheckbox must be used within a FormProvider");
  }

  return (
    <div className={className}>
      <Controller
        name={fieldName}
        control={contextValue.control}
        render={({ field }) => (
          <div className={cn(`flex items-center gap-2 ${containerClassName ?? ""}`)}>
            <Checkbox
              disabled={disabled ? true : false}
              checked={isControlled ? value : field.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e.target.checked);
                if (onChange) onChange(e.target.checked, fieldName);
              }}
            />
            <p className={cn(`text-[#7b7a7c] font-medium text-xs ${labelClassName ?? ""}`)}>{label}</p>
          </div>
        )}
      />
      <ErrorMessage
        label={label}
        type={contextValue?.errors[fieldName]?.type as string}
        show={!!contextValue.errors[fieldName]?.message}
        message={(contextValue.errors[fieldName]?.message as string) || ""}
      />
    </div>
  );
};

// Expose Input and Autocomplete components from GenericForm

SmartForm.Input = SmartFormInput;
SmartForm.Autocomplete = SmartFormAutocomplete;
SmartForm.Toggle = SmartFormToggle;
SmartForm.TextArea = SmartFormTextArea;
SmartForm.MultiAutocomplete = SmartFormMultiAutocomplete;
SmartForm.Checkbox = SmartFormCheckbox;

export default SmartForm;
