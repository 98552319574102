import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { generateUniqueID } from "src/constants/functions";
import { ApiBlocker, api, apiValidations, changeApiBlocker, changeShowErrorToast, emailMobileCheck, logout } from "src/constants/global-const";
import { addAuthHeader } from "src/utils/auth";

let instance: AxiosInstance;
let eventInstance: AxiosInstance;
let instanceValidations: AxiosInstance;

const extractUrlParams = (url: string): Record<string, string> => {
  const params: Record<string, string> = {};
  const queryString = url.split("?")[1];
  if (queryString) {
    const pairs = queryString.split("&");
    for (const pair of pairs) {
      const [key, value] = pair.split("=");
      params[key] = decodeURIComponent(value);
    }
  }
  return params;
};

let pendingTimer: any;

const handlePendingState = (config: any, resolve: any, reject: any, attempt = 0) => {
  const params = new URLSearchParams(window.location.search);
  if (ApiBlocker.isEventPending) {
    if (attempt >= 10) {
      reject(new Error("Maximum retry attempts reached."));
    }
    clearTimeout(pendingTimer); // Clear the previous timer
    pendingTimer = setTimeout(() => {
      const counterId = params.get("id");
      if (counterId) ApiBlocker.eventChecked(`counter_${counterId}`);
      handlePendingState(config, resolve, reject, attempt + 1); // Recursively call the function until the event is not pending
    }, 1000); // Wait for 1 second before checking again
  } else {
    resolve(addAuthHeader(config)); // Proceed with the API call if not pending
  }
};

const axiosInstance = async () => {
  instance = axios.create({
    baseURL: api + "/api/v1/employee",
  });
  // Add the interceptor to the instance
  instance.interceptors.response.use(undefined, (error) => {
    if (error?.response?.status === 403) {
      // logout
      logout();
    }
  });

  instance.interceptors.request.use(
    (success: InternalAxiosRequestConfig) => {
      // debugger;
      let employeeString;
      const params = success?.url?.split("?")?.[1];
      if (params && (params.includes("undefined") || params.includes("null"))) {
        console.error("Request cancelled due to undefined parameters.");
        return Promise.reject("Request cancelled due to undefined parameters.");
      }
      const val = extractUrlParams(success?.url as string);
      if (success?.baseURL?.includes("employee") && success?.url?.includes("empmaster_id") && val?.empmaster_id) {
        employeeString = `employee_${val?.empmaster_id}`;
        employeeString && ApiBlocker.eventChecked(employeeString);
      }
      return addAuthHeader(success);
    },
    (error) => {
      // alert("asdfasdf");
      if (error?.response?.status === 403) {
        // logout
        // logout();
      }
    }
  );
};

const axiosEventInstance = async () => {
  eventInstance = axios.create({
    baseURL: api + "/event/employee",
  });

  // Add the interceptor to the instance
  eventInstance.interceptors.response.use(
    (success: any) => {
      console.log("event success", success?.data?.eventStreamCreated?.streamId);
      ApiBlocker.eventChecked(success?.data?.eventStreamCreated?.streamId);
      return success;
    },
    (error) => {
      // alert("asdfasdf1111");
      if (error?.response?.status === 403) {
        // logout
        logout();
      }
    }
  );

  eventInstance.interceptors.request.use(
    (config) => {
      return new Promise((resolve, reject): any => {
        if (
          (ApiBlocker.isEventBlocked || ApiBlocker.isEventPending) &&
          config.url !== "/add" &&
          window.location.pathname.toLowerCase().includes("/details")
        ) {
          const errorMessage = ApiBlocker.isEventBlocked
            ? "Request cancelled due to event blocked status"
            : "Request cancelled due to event pending status";
          changeShowErrorToast(true, errorMessage);
          changeApiBlocker(false, true);
          reject(errorMessage);
        } else {
          handlePendingState(config, resolve, reject, 1);
        }
      });
    },
    (error) => {
      if (error?.response?.status === 403) {
        logout();
      }
      return Promise.reject(error);
    }
  );
};

const axiosInstanceValidations = async () => {
  instanceValidations = axios.create({
    baseURL: apiValidations,
  });
  // Add the interceptor to the instance
  instanceValidations.interceptors.response.use(undefined, (error) => {
    if (error?.response?.status === 403) {
      // logout
      logout();
    }
  });
};

axiosEventInstance();

axiosInstance();
axiosInstanceValidations();

interface ApiResponse {
  success: boolean;
  message: string;
  data?: any;
  status?: number;
  // Add other properties as needed
}

interface LowerHierarchyProps {
  type: string;
  relation: string;
  user: string;
  company_code: string | any;
}
interface UpperHierarchyProps {
  relation: string;
  object: string;
  company_code: string | any;
}

export const queryEmployeeDetails = async (query: string): Promise<AxiosResponse> => {
  if (query && (query.includes("null") || query.includes("undefined") || query.includes("null")))
    throw new Error("Request cancelled due to undefined parameters.");
  return instance.get(query);
};

export const addEmployee = async (data: any) => {
  return eventInstance.post("/add", {
    data: { ...data, active_status: "Y", u_code: generateUniqueID() },
    event_type: "EMPLOYEE_ADD",
  });
};

export const editEmployee = async (data: any, primary_key: string | number | null | undefined) => {
  // debugger;
  if (!primary_key) throw new Error();
  return eventInstance.put("/edit", {
    data: { ...data, empmaster_id: primary_key },
    event_type: "EMPLOYEE_EDIT",
    primary_key: primary_key,
  });
};

export const queryUserType = async (companyCode: number | string) => {
  return instance.get("/user-type/details?company_code=" + companyCode);
};

export const getHierarchyLabels = async (companyCode: number | string) => {
  return instance.get("/role/details?company_code= " + companyCode);
};

export const getLowerHierarchy = async (record: LowerHierarchyProps): Promise<ApiResponse> => {
  return instance.post(`/hierarchy/lower`, record);
};

export const getUpperHierarchy = async (record: UpperHierarchyProps | any): Promise<ApiResponse> => {
  return instance.post(`/hierarchy/upper`, record);
};

export const queryEmployeeManager = async (
  user_type: string | number,
  company_id: number | string,
  highest_manager: string | number,
  divisionId: number | string
) => {
  return instance.get(`/managers?usertype=${user_type}&company_id=${company_id}&highest_manager=${highest_manager}&division_id=${divisionId}`);
};

export const getPositionCodeStatus = (positionCode: string | number, company_code: string | number) => {
  return instance.get(`/position-code/status?position_code=${positionCode}&company_code=${company_code}`);
};
export const getPositionCodeStatusName = (positionCode: string | number, company_code: string | number) => {
  return instance.get(`/position-code/status?position_code_name=${positionCode}&company_code=${company_code}`);
};

export const getShortCodeStatus = (shortCode: string | number, company_code: string | number) => {
  return instance.get(`/short-code/status?emp_shortcode=${shortCode}&company_code=${company_code}`);
};

export const deactivateEmployee = async (data: any): Promise<ApiResponse> => {
  return instance.put("/edit", data);
};

export const checkGstStatus = async (data: any): Promise<ApiResponse> => {
  return instance.put(`/validate?gst_number=${data}`);
};

export const checkMobileStatus = async (data: any, query?: string): Promise<ApiResponse> =>
  // instance.get(`/mobilenumber/status?mobile_no=${data}&company_code=${company_code}`);
  axios.get(`${emailMobileCheck}?mobile_no=${data}${query ? `&${query}` : ""}`);

// export const checkGstStatus = async (data: any): Promise<ApiResponse> =>
// // instance.get(`/mobilenumber/status?mobile_no=${data}&company_code=${company_code}`);
// axios.get(`${emailMobileCheck}?mobile_no=${data}`);

export const checkEmailStatus = async (data: any, query?: string): Promise<ApiResponse> =>
  // instance.get(`/email/status?email_id=${data}&company_code=${company_code}`);
  axios.get(`${emailMobileCheck}?email=${data}${query ? `&${query}` : ""}`);

export const checkHeadquarterStatus = async (data: any, company_code: string | number): Promise<ApiResponse> =>
  instance.get(`/headquarter/status?headQuarter_code=${data}&company_code=${company_code}`);

export const validateUniquePosCodeAndShortCode = async (data: any): Promise<ApiResponse> => instance.post(`/validateUnique`, data);

export const checkMobileStatusMedvol = async (mobile_no?: string, query?: string): Promise<ApiResponse> =>
  instanceValidations.get(`/checkUnique?mobile_no=${mobile_no}${query ? `&${query}` : ""}`);

export const checkEmailStatusMedVol = async (email?: string, query?: string): Promise<ApiResponse> =>
  instanceValidations.get(`/checkUnique?email=${email}${query ? `&${query}` : ""}`);

export const checkAltEmpCodeStatus = async (emp_code: string | number, company_code: string | number): Promise<ApiResponse> =>
  instance.get(`/alt-short-code/status?alternate_emp_code=${emp_code}&company_code=${company_code}`);

export const checkUniqueBulk = async (data: any): Promise<ApiResponse> => instanceValidations.post(`/checkUnique/bulk`, data);

export const getMedvolUsers = async () => {
  return instance.get(`/staffposition/details`);
};
